import { Gondor } from "../Gondor";

const ACTIONS = {
  LEAD: "/report/lead",
  HISTORY: "/report/apihistory",
};

export function lead(body: object) {
  return Gondor.API.post(ACTIONS.LEAD, body);
}

export const apiHistory = (
  apiSystemId: string,
  startDate?: string,
  endDate?: string
) => {
  return Gondor.API.post(ACTIONS.HISTORY, {
    apiSystemId: Number(apiSystemId),
    startDate,
    endDate,
  });
};
